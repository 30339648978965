const breakpoint = 1024;
const header = document.querySelector('header');

if(header) {
    const wrapperContent = header.querySelector('.wrapper').innerHTML;
    let headerHeight = header.offsetHeight;
    let lastScrollTop = 0;

    // Menu on scroll
    $(window).scroll(function (e) {
        let scrollTop = $(this).scrollTop();
        if ($(window).width() >= breakpoint && scrollTop <= 2) {
            header.classList.remove('sticky', 'show', 'animate');
        } 
        else if (scrollTop === 0) {
            header.classList.remove('sticky', 'show', 'animate');
        } 
        else if (scrollTop > headerHeight) {
            if (scrollTop < lastScrollTop) { // Scrolling up
                header.classList.add('sticky', 'animate', 'show')
            } else {
                header.classList.add('sticky');
                header.classList.remove('show');
            }
        }
        lastScrollTop = scrollTop;
    });

    setMenuNav();

    window.addEventListener('resize', function() {
        header.querySelector('.wrapper').innerHTML = wrapperContent;
        setMenuNav();
    });

    function openMenu(menu) {
        document.querySelector("." + menu + '-menu').classList.add('show');
        document.querySelector("header .menu-content").classList.add("show");
        header.classList.add('show-menu');
        document.querySelector("body").classList.add('no-scroll');
        
        // Hides dropdown when menu is hidden
        document.querySelectorAll(".menu-item-has-children >a").forEach(function (ele) {
            ele.classList.remove("show-ul");
        });
    }

    function closeMenu() {
        document.querySelector('header button.active').classList.remove('active');
        document.querySelector("header .menu-content").classList.remove("show");
        header.classList.remove('show-menu');
        document.querySelector("body").classList.remove('no-scroll');
        setTimeout(function() {
            document.querySelector("ul.show").classList.remove('show');
        }, 500);
    }

    function setMenuNav() {
        document.querySelector(".menu-button").onclick = function toggleMenu() {
            if(this.classList.contains('active')) {
                closeMenu();
            }
            else {
                if(document.querySelector(".investors-menu").classList.contains('show')) {
                    document.querySelector(".investors-menu").classList.remove('show');
                    document.querySelector('header button.active').classList.remove('active');
                }
                this.classList.add('active');
                openMenu('main');
            }
        };

        document.querySelector(".investors-button").onclick = function toggleMenu() {
            if(this.classList.contains('active')) {
                closeMenu();
            }
            else {
                if(document.querySelector(".main-menu").classList.contains('show')) {
                    document.querySelector(".main-menu").classList.remove('show');
                    document.querySelector('header button.active').classList.remove('active');
                }
                this.classList.add('active');
                openMenu('investors');
            }
        }

        //dropdown
        /**
         * check if parent has child with classname
         * on click add or remove class
        */
        document.querySelectorAll(".menu-item-has-children >a").forEach(function (ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    window.location = e.target.href;
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                }
            });
        });
        document.querySelectorAll(".menu-item-has-children >svg").forEach(function(ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    ele.parentNode.classList.remove("show-ul");
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                }
            });
        });
    }
}