let heroBlock;
if(document.querySelector('section.hero-banner')) {
    heroBlock = document.querySelector('section.hero-banner');
}
else if(document.querySelector('section.hero')) {
    heroBlock = document.querySelector('section.hero');
}

document.fonts.ready.then(() => {
    if(heroBlock) {
        let continueIndicator = heroBlock.querySelector('a.continue-indicator');
        let nextSection = heroBlock.nextElementSibling;

        if(continueIndicator) {
            continueIndicator.addEventListener('click', function(e) {
                e.preventDefault();
                let parent = this.closest('section');
                let next = parent.nextElementSibling;
                let nextStyle = next.currentStyle || window.getComputedStyle(next);
                let offset = parseInt(nextStyle.marginTop);

                if (next) {
                    $('html, body').animate({
                        scrollTop: next.offsetTop - offset
                    }, 400);
                }
            });
        }

        if(nextSection && nextSection.classList.contains('no-section-title')) {
            let titleWrapper = heroBlock.querySelector('.title-wrapper');
            let startColor = 255;
            let targetColor = 22;
            let colorDiff = startColor - targetColor;
            if(heroBlock.classList.contains('text-color-black')) {
                startColor = 22;
                targetColor = 255;
                colorDiff = targetColor - startColor;
            }
            let titleOffsetTop = heroBlock.querySelector('.scroll-wrapper').offsetTop;
            let defaultTitleOffsetTop = nextSection.offsetTop;
            let titleColorTransitionHeight = 500;

            $(window).scroll(moveHeroTitle);

            $(window).resize(function() {
                titleWrapper.style.transform = 'none';
                titleOffsetTop = heroBlock.querySelector('.scroll-wrapper').offsetTop;
                defaultTitleOffsetTop = nextSection.offsetTop;
        
                moveHeroTitle();
            });

            function moveHeroTitle() {
                if ((heroBlock.classList.contains('hero-banner') && window.innerWidth < 1024) || (heroBlock.classList.contains('hero') && window.innerWidth < 1440)) {
                    titleWrapper.style.transform = 'none';
                    if(heroBlock.classList.contains('text-color-black')) {
                        titleWrapper.querySelector('h1').style.color = '#161614';
                        if(titleWrapper.querySelector('svg')) {
                            titleWrapper.querySelector('svg').style.borderColor = '#161614';
                            titleWrapper.querySelectorAll('svg path').forEach(function(path) {
                                path.style.fill = '#161614';
                            });
                        }
                    }
                    else {
                        titleWrapper.querySelector('h1').style.color = '#FBF9F8';
                        if(titleWrapper.querySelector('svg')) {
                            titleWrapper.querySelector('svg').style.borderColor = '#FBF9F8';
                            titleWrapper.querySelectorAll('svg path').forEach(function(path) {
                                path.style.fill = '#FBF9F8';
                            });
                        }
                    }

                    return;
                }

                let scrollTop = $(this).scrollTop();

                let diff = defaultTitleOffsetTop - titleOffsetTop - 10;
                let speed = 1.25;
                let progress = scrollTop / titleColorTransitionHeight / speed;
        
                let translateY = Math.min(scrollTop / speed, diff);
                let rgbColor = Math.max(startColor - progress * colorDiff, targetColor);
                if(heroBlock.classList.contains('text-color-black')) {
                    rgbColor = Math.min(startColor + progress * colorDiff, targetColor);
                }
                let newColor = 'rgb(' + rgbColor + ', ' + rgbColor + ', ' + (rgbColor - 2) + ')';
        
                titleWrapper.style.transform = 'translateY(' + translateY + 'px)';
                titleWrapper.querySelector('h1').style.color = newColor;
                if(titleWrapper.querySelector('svg')) {
                    titleWrapper.querySelector('svg').style.borderColor = newColor;
                    titleWrapper.querySelectorAll('svg path').forEach(function(path) {
                        path.style.fill = newColor;
                    });
                }

            }
      
        }
    }
});