const reportsBlock = document.querySelector('section.reports');

if(reportsBlock) {
  let typeSelect = reportsBlock.querySelector('select#type-select');
  let yearSelect = reportsBlock.querySelector('select#year-select');
  let selectedType = 'all';
  let selectedYear = 'all';
  let sections = reportsBlock.querySelectorAll('.report-section');

  typeSelect.addEventListener('change', function(e) {
    selectedType = this.value;
    if(this.value != 'all') {
      this.querySelector('option[value="all"]').innerHTML = 'All';
    }
    else {
      this.querySelector('option[value="all"]').innerHTML = 'Category';
    }
    filterReports();
  });

  yearSelect.addEventListener('change', function(e) {
    selectedYear = this.value;
    if(this.value != 'all') {
      this.querySelector('option[value="all"]').innerHTML = 'All';
    }
    else {
      this.querySelector('option[value="all"]').innerHTML = 'Year';
    }
    filterReports();
  });

  function filterReports() {

    sections.forEach(function(section) {
      section.classList.remove('show');
      if(selectedYear == 'all' || (section.getAttribute('data-year') == selectedYear)) {
        section.classList.add('show');
      }
      section.querySelectorAll('.row').forEach(function(row) {
        row.classList.remove('show');
        if(selectedType == 'all' || (row.classList.contains('type-' + selectedType))) {
          row.classList.add('show');
        }
      });
    });

    reportsBlock.querySelectorAll('.report-section.show').forEach(function(section) {
      let displayedRows = section.querySelectorAll('.row.show');
      if(displayedRows.length == 0) {
        section.classList.remove('show');
      }
    });

    if(reportsBlock.querySelectorAll('.report-section.show').length == 0) {
      reportsBlock.querySelector('.no-results-text').classList.add('show');
    }
    else {
      reportsBlock.querySelector('.no-results-text').classList.remove('show');
    }

  }
}