document.addEventListener('DOMContentLoaded', function() {
    const table = document.querySelector('.major-shareholders table');
    if (!table) return;

    const tbody = table.querySelector('tbody');
    const top20TotalCell = table.querySelector('.top-20-total');
    const top20TotalPercentageCell = table.querySelector('.top-20-total-percentage');
    const totalSharesCell = table.querySelector('.total-shares');

    async function fetchShareholderData() {
        try {
            const response = await fetch('https://ir.oms.no/server/secure/components?auth=key%3dOKEA&product=shareholders');
            const data = await response.json();

            // Filter shareholders and get totals
            const shareholders = data.rows.filter(row => row.key.includes('OKEA_OSE0'));
            const totalsRow = data.rows.find(row => row.key.includes('generators_1'));
            const totalShares = totalsRow?.values?.TOTAL_NUMBER_OF_SHARES || 0;

            // Calculate total shares for top 20
            const totalTop20Shares = shareholders.reduce((sum, shareholder) =>
                sum + shareholder.values.TOP_POSITION_SHARES_, 0);

            // Generate table rows
            const tableRows = shareholders.map(shareholder => {
                const percentageOfTop20 = ((shareholder.values.TOP_POSITION_SHARES_ / totalTop20Shares) * 100).toFixed(1);
                const percentageOfTotal = ((shareholder.values.TOP_POSITION_SHARES_ / totalShares) * 100).toFixed(1);

                return `
                    <tr>
                        <td>${shareholder.values.TOP_INVESTOR_}</td>
                        <td>${shareholder.values.TOP_POSITION_SHARES_.toLocaleString()}</td>
                        <td>${percentageOfTop20}%</td>
                        <td>${percentageOfTotal}%</td>
                        <td>${shareholder.values.TOP_INVESTOR_ACCOUNT_TYPE_}</td>
                        <td>${shareholder.values.TOP_INVESTOR_COUNTRY_}</td>
                    </tr>
                `;
            }).join('');

            // Update the table
            tbody.innerHTML = tableRows;

            // Update footer totals
            top20TotalCell.textContent = totalTop20Shares.toLocaleString();
            const totalTop20Percentage = ((totalTop20Shares / totalShares) * 100).toFixed(1);
            top20TotalPercentageCell.textContent = totalTop20Percentage + '%';
            totalSharesCell.textContent = totalShares.toLocaleString();

        } catch (error) {
            console.error('Error fetching shareholder data:', error);
            tbody.innerHTML = '<tr><td colspan="6" class="loading">Error loading shareholder data</td></tr>';
        }
    }

    fetchShareholderData();
});