const assetsBlock = document.querySelector('section.assets');

if(assetsBlock) {
    let assets = assetsBlock.querySelectorAll('.tease-asset');
    assets.forEach(function(asset){
        let video = asset.querySelector('video') ?? null;
        asset.addEventListener('mouseover', function() {
            if(video && video.querySelector('source').getAttribute('src') == '') {
                let source = video.getAttribute('data-src');
                video.querySelector('source').setAttribute('src', source);
                video.load();
                video.addEventListener('loadeddata', playVideo(asset));
            }
        });
        asset.addEventListener('mouseleave', function() {
            if(video) {
                video.removeEventListener('loadeddata', playVideo(asset));
                video.classList.remove('show');
                let img = asset.querySelector('img') ?? null;
                if(img) {
                    img.classList.remove('hide');
                }
                video.pause();
                video.querySelector('source').setAttribute('src', '');
            }
        });
    });
    function playVideo(asset) {
        asset.querySelector('video').play();
        let img = asset.querySelector('img') ?? null;
        if(img) {
            img.classList.add('hide');
        }
        asset.querySelector('video').classList.add('show');
    }
}