import lottie from 'lottie-web';

document.addEventListener('DOMContentLoaded', function() {
    const introContainer = document.querySelector('.frontpage-intro');
    const header = document.querySelector('header');

    if (introContainer && header) {
        // Initially hide the header
        header.style.opacity = '0';

        // Check if animation has already been played
        if (sessionStorage.getItem('introPlayed')) {
            introContainer.remove();
            header.style.opacity = '1';
            return;
        }

        // Load and play the animation
        const anim = lottie.loadAnimation({
            container: document.getElementById('lottie-container'),
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: '/wp-content/themes/grensesnitt/static/animations/data.json'
        });

        // When animation completes
        anim.addEventListener('complete', () => {
            introContainer.style.opacity = '0';

            setTimeout(() => {
                introContainer.remove();
                // Fade in the header
                header.style.opacity = '1';
            }, 500);

            sessionStorage.setItem('introPlayed', 'true');
        });
    }
});