const videoBlocks = document.querySelectorAll('section.image-video-block video');

if(videoBlocks.length > 0) {
    videoBlocks.forEach(function(video, index) {
        let fullVideo = video.getAttribute('data-full-src');
        let playIcon = video.parentElement.querySelector('p.play-btn');
        let overlay = video.parentElement.querySelector('.overlay');
        if(playIcon) {
            playIcon.addEventListener('click', function() {
                let newVideo = video.cloneNode(true);
                video.after(newVideo);
                newVideo.classList.add('hide', 'new-video');
                newVideo.setAttribute('src', fullVideo);
                newVideo.addEventListener('canplay', function () {
                    newVideo.classList.remove('hide');
                    
                    setTimeout(function() {
                        video.classList.add('hide');
                        newVideo.classList.remove('new-video');
                        video.remove();
                    }, 500);
                }); 
                newVideo.setAttribute('controls', 'true');
                newVideo.setAttribute("controlslist", "nodownload noplaybackrate noremoteplayback");
                newVideo.setAttribute("disablepictureinpicture", true);
                playIcon.parentElement.classList.add('hide');
                overlay.classList.add('hide');
            });
        }
    });
}