document.addEventListener('DOMContentLoaded', function() {
    const wordElements = document.querySelectorAll('.cycling-words .word');
    let currentIndex = 0;

    function showNextWord() {
        wordElements.forEach(word => word.classList.remove('active'));
        wordElements[currentIndex].classList.add('active');

        const duration = parseFloat(wordElements[currentIndex].dataset.duration) * 1000;
        currentIndex = (currentIndex + 1) % wordElements.length;

        setTimeout(showNextWord, duration);
    }

    if (wordElements.length > 0) {
        showNextWord();
    }
});