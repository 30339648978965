import 'slick-carousel/slick/slick';

function initSlider(id) {
    $('#' + id).slick({
        infinite: false,
        useTransform: false,
        dots: false,
        arrows: false,
        speed: 400,
        touchThreshold: 10,
        slidesToShow: 1.95,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1650,
            settings: {
              slidesToShow: 2.35
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2.325
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1
            }
          }
        ]
    });
};
window.initSlider = initSlider;

function initTextSlider(id){
    $('#' + id).slick({
        infinite: false,
        useTransform: false,
        dots: true,
        arrows: false,
        speed: 400,
        touchThreshold: 10,
        slidesToShow: 1.05,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1650,
                settings: {
                    slidesToShow: 1.33
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.25
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.05
                }
            }
        ]
    });
};
window.initTextSlider = initTextSlider;