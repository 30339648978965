import Chart from 'chart.js/auto';

function setupChart(id, labels, datasets) {
  let canvas = document.querySelector('#pieChart_' + id);
  canvas.classList.add('initalized');
  let ctx = canvas.getContext('2d');
  
  let chartData = {
      labels: labels,
      datasets: datasets
  };
  
  let pieChart = new Chart(ctx, {
      type: 'pie',
      data: chartData,
      options: {
        responsive: true,
        interaction: {
          intersect: true
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            titleFont : {
              family: "'Montserrat', sans-serif",
              size: 16
            },
            bodyFont: {
              family: "'Montserrat', sans-serif",
              size: 16
            },
              callbacks: {
                label: function(context) {
                  if(context.raw != '') {
                    return ' ' + context.formattedValue + "%";                                
                  }
                  else {
                      return null;
                  }
                }
              }
          }
        }
      },
    });
  }
window.setupChart = setupChart;